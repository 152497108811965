import { Moment } from "moment";

export class ExpiredMed {
    id: string;
    peak: string;
    packType: string;
    packNumber: string;
    med: string;
    completed: boolean = false;
    completer: string;
    dateCompleted: number;
    quantity: number;
    status: string = "Expired";
}
