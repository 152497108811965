<div *ngIf="!authService.isDispatch(user)" class="container" id="cont1">
    <app-records (onRecordClicked)="onRecordClicked($event)"></app-records>
</div>
<div *ngIf="authService.isDispatch(user) && !authService.isFullDispatch(user)" class="container" id="cont2">
    <app-records (onRecordClicked)="onRecordClicked($event)"></app-records>
    <app-record-detail [record]="currentRecord"></app-record-detail>
</div>
<div *ngIf="authService.isDispatch(user) && authService.isFullDispatch(user)" class="container" id="cont3">
    <app-records (onRecordClicked)="onRecordClicked($event)"></app-records>
    <div id="cont3a" class="container">
        <div id="cont3b" class="container">
            <app-record-detail [record]="currentRecord"></app-record-detail>    
            <app-notes></app-notes>
        </div>
        <!--<app-wig></app-wig>-->
    </div>
</div>
            