import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from '../../../app/services/user.service';
import { DailyWeatherObservation } from '../../objects/daily-weather-observation';
import { WeatherService } from '../../services/weather.service';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { EditHistoricalComponent } from '../edit-historical/edit-historical.component';

@Component({
  selector: 'app-weather-report',
  templateUrl: './weather-report.component.html',
  styleUrls: ['./weather-report.component.scss']
})
export class WeatherReportComponent implements OnInit, OnDestroy {

  report = new DailyWeatherObservation();
  currentDate = moment();
  patrollers = [];

  subscription = new Subscription();

  constructor(private userService: UserService, private weatherService: WeatherService, private snackbar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.userService.getCurrentPatrollers().pipe(take(1)).subscribe(patrollers => {
      this.patrollers = patrollers;
    });

    this.weatherService.getWeatherObsForDate(this.currentDate).subscribe(report => {
      if (report.data()) {
        this.report = report.data() as DailyWeatherObservation;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDateChanged() {
    this.report = new DailyWeatherObservation();
    this.weatherService.getWeatherObsForDate(this.currentDate).subscribe(report => {
      if (report.data()) {
        this.report = report.data() as DailyWeatherObservation;
      }
    });
  }

  onSubmitClick() {
    // If it doesn't have a timestamp, it's new, so give it today's date in ms.
    if (this.report.timestamp == null) {
      this.report.timestamp = this.currentDate.add(5, 'hours').valueOf();
    }
    
    this.weatherService.updateWeatherObs(this.report);
    this.snackbar.open('Observation submitted successfully', '', { duration: 2000, panelClass: ['success-snackbar'] });
  }

  onClearClick() {
    this.report = new DailyWeatherObservation();
  }

  onEditHistoricalClick() {
    this.dialog.open(EditHistoricalComponent);
  }
}
