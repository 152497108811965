<h2>Guest Service Initiative</h2>

<div id="tab-container" mat-align-tabs="center">
    <mat-tab-group mat-stretch-tabs (selectedTabChange)="onTabChange($event)">
        <mat-tab label="Frontside">
            <div class="tab-body">
                <button class="big-button" mat-raised-button color="primary" (click)="onButtonClick('frontside', 1)">15-minutes of PR (+1)</button>
                <button class="big-button" mat-raised-button color="accent" (click)="onButtonClick('frontside', 4)">Training in Public (+4)</button>

                <br>

                <div>
                    <table>
                        <tr>
                            <th>Saturday</th>
                            <th>Sunday</th>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                        </tr>
                        <tr>
                            <td>{{frontsidePoints[0]}}</td>
                            <td>{{frontsidePoints[1]}}</td>
                            <td>{{frontsidePoints[2]}}</td>
                            <td>{{frontsidePoints[3]}}</td>
                            <td>{{frontsidePoints[4]}}</td>
                            <td>{{frontsidePoints[5]}}</td>
                            <td>{{frontsidePoints[6]}}</td>
                        </tr>
                    </table>
                </div>
                <br>
                Last week total: <div class="points">{{ frontsidePoints[7] }}</div>
            </div>
        </mat-tab>
        <mat-tab label="North Peak">
            <div class="tab-body">
                <button class="big-button" mat-raised-button color="primary" (click)="onButtonClick('north-peak', 1)">15-minutes of PR (+1)</button>
                <button class="big-button" mat-raised-button color="accent" (click)="onButtonClick('north-peak', 4)">Training in Public (+4)</button>

                <br>

                Points today: <div class="points">{{ northPeakPoints }}</div>
            </div>
        </mat-tab>
        <mat-tab label="Outback">
            <div class="tab-body">
                <button class="big-button" mat-raised-button color="primary" (click)="onButtonClick('outback', 1)">15-minutes of PR (+1)</button>
                <button class="big-button" mat-raised-button color="accent" (click)="onButtonClick('outback', 4)">Training in Public (+4)</button>

                <br>

                Points today: <div class="points">{{ outbackPoints }}</div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>

