import { Component, OnInit, OnDestroy } from '@angular/core';
import { WigService } from '../../services/wig.service';
import { Subscription } from 'rxjs';
import { Wig } from '../../objects/wig';

@Component({
  selector: 'app-wig',
  templateUrl: './wig.component.html',
  styleUrls: ['./wig.component.css']
})
export class WigComponent implements OnInit, OnDestroy {

  frontsidePoints = [0, 0, 0, 0, 0, 0, 0, 0];
  northPeakPoints = 0;
  outbackPoints = 0;

  currentTabIndex = 0;
  currentPeak = "frontside";

  subscription = new Subscription();

  constructor(private wigService: WigService) { }

  ngOnInit() {
    this.getPointsForTab();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onTabChange($event) {
    this.currentTabIndex = $event.index;
    switch (this.currentTabIndex) {
      case 0: 
        this.currentPeak = "frontside";
        break;
      case 1: 
        this.currentPeak = "north-peak";
        break;
      case 2: 
        this.currentPeak = "outback";
        break;
    }
    this.getPointsForTab();
  }

  getPointsForTab() {
    this.subscription.add(this.wigService.getPointsForPeakToday(this.currentPeak).subscribe(wigs => {
      let total = 0;
      wigs.forEach(wig => {
        total += wig.value;
      });

      switch (this.currentTabIndex) {
        case 0:
          //this.frontsidePoints = total;
          break;
        case 1:
          this.northPeakPoints = total;
          break;
        case 2: 
          this.outbackPoints = total;
          break;
      }
    }));
  }

  onButtonClick(peak: string, value: number) {
    let wig = new Wig()
    wig.peak = peak;
    wig.value = value;
    this.wigService.addWig(wig);
  }
}
