<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<h2 mat-dialog-title>Add a morning meeting note</h2>
<mat-dialog-content>
  <div id="content-container">
    <mat-form-field>
        <textarea matInput placeholder="Text" name="text" [(ngModel)]="note.text"></textarea>
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Posted by" name="author" [(ngModel)]="note.author">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Expiry date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="note.expiryMoment">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onSubmitClicked()" [mat-dialog-close]="true">Submit</button>
  <button mat-raised-button color="accent" (click)="onArchiveClicked()" [mat-dialog-close]="true">Archive</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button class="end-align" mat-raised-button (click)="onDeleteClicked()" [mat-dialog-close]="true">Delete ☠</button>
</mat-dialog-actions>