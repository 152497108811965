<h2>Useful Links</h2>

<div id="container">
    <div id="links-container">
      <ul>
        <li *ngFor="let linkGroup of linkGroups" class="link-group-header">
          <div class="hoverable" id="link-group-header">
            <h4 style="display: block; float: left; margin-top: 12px;">{{linkGroup.name}}</h4>
            <button *ngIf="isSpecialist()" class="hover-buttons" mat-icon-button (click)="onEditButtonClicked(null, linkGroup)">
              <mat-icon class="material-icons">add</mat-icon>
            </button>
          </div>
          <ul>
            <li *ngFor="let link of linkGroup.links" class="link-list-item hoverable">
              <a mat-button href="{{link.link}}" color="primary" target="_blank" rel="noopener">{{link.text}}</a>
              <button *ngIf="isSpecialist()" class="hover-buttons" mat-icon-button (click)="onEditButtonClicked(link, linkGroup)">
                <mat-icon class="material-icons">edit</mat-icon>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
</div>
