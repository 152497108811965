<h2 mat-dialog-title>Report missing or expired medical supplies</h2>
<mat-dialog-content>
  <div id="content-container">
    <mat-form-field>
        <mat-select placeholder="Peak" name="peak" [(ngModel)]="report.peak">
          <mat-option value="Frontside">Frontside</mat-option>
          <mat-option value="North Peak">North Peak</mat-option>
          <mat-option value="Outback">Outback</mat-option>
          <mat-option value="Bergman">Bergman</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-select placeholder="Pack type" name="type" [(ngModel)]="report.packType">
          <mat-option value="BLS">BLS</mat-option>
          <mat-option value="Crash">Crash</mat-option>
          <mat-option value="ALS">ALS</mat-option>
        </mat-select>
    </mat-form-field>
    
    <mat-form-field>
      <input matInput placeholder="Pack Number" name="packNumber" [(ngModel)]="report.packNumber">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Med/supply" name="med" [(ngModel)]="report.med">
    </mat-form-field>

    <div class="radio-container">
      <mat-radio-group [(ngModel)]="report.status" name="status" class="radio-group" layout="row">
        <mat-radio-button value="Expired" class="radio">Expired</mat-radio-button>
        <mat-radio-button value="Missing" class="radio">Missing</mat-radio-button>
      </mat-radio-group>
    </div>
    
    <mat-form-field>
      <input matInput placeholder="Quantity" type="number" name="quantity" [(ngModel)]="report.quantity">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onSubmitClicked()" mat-dialog-close="true">Submit</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>