<div id="container">
    <div id="outer-img-container">
        <div id="img-container">
            <img src="../../../assets/patrol-join-ffl.png" alt="source" title="Zak Bloom - @zakisoutside">
            <img src="../../../assets/patrol-join-xmas.jpeg" alt="source">
            <img src="../../../assets/patrol-join-faceshot.jpg" alt="source">
            <img src="../../../assets/patrol-join-sunset.jpeg" alt="source">
            <img src="../../../assets/patrol-join-snowmo.png" alt="source" title="Kyle Sovada - @kylesovada">
            <img src="../../../assets/patrol-join-dogs.jpg" alt="source">
            <img src="../../../assets/patrol-join-snowboard.png" alt="source" title="Kyle Sovada - @kylesovada">
            <img src="../../../assets/patrol-join-fisher.jpeg" alt="source">
            <img src="../../../assets/patrol-join-tubing.jpeg" alt="source">
            <img src="../../../assets/patrol-join-dog.png" alt="source" title="@davecamara1">
        </div>
    </div>

    <br>
    <br>
    <br>

    <div id="text">
        Do you want to work outside in the Rocky Mountains?
        <br>
        Do you love riding steeps and powder?
        <br>
        Are you ambitious and a hard-worker?
        <br>
        ***
        <br>
        Well then, Keystone Ski Patrol may be perfect for you!  Keystone Ski Patrol is holding a hiring clinic from April 3-5th, 2020 for both skiers and snowboarders alike!  
        <br>
        <a href="https://career8.successfactors.com/sfcareer/jobreqcareer?jobId=207053&company=Vail" target="_blank">Click here</a> for more information on how to apply.
    </div>

    <br>
    <br>
    <br>

    <a href="https://www.instagram.com/skistonekeypatrol/" target="_blank">
        <img id="insta" src="../../../assets/insta-logo.png">
    </a>
    

    <br>
    <br>

    <img id="logo" src="../../../assets/ksp-join-logo.png">
    
    
</div>
