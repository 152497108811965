import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DashboardService } from '../../../app/services/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AddMorningNoteComponent } from '../dialogs/add-morning-note/add-morning-note.component';
import { MorningNote } from '../../objects/morning-note';
import { AuthService } from '../../services/auth.service';

import html2canvas from 'html2canvas';
import { Subscription } from 'rxjs';
import { TrainingLink } from '../../objects/training-link';
import { TrainingLinkEditComponent } from '../dialogs/training-link-edit/training-link-edit.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  subscription = new Subscription();

  // Morning Meeting Notes tab.
  morningMeetingNotes = [];
  tableDataSource: MatTableDataSource<MorningNote>;
  displayedColumns: string[] = ['posted', 'expires', 'postedBy', 'note'];
  displayedColumnsWithEdit: string[] = ['posted', 'expires', 'postedBy', 'note', 'edit'];
  editFlag = true;

  archivedNotes = [];
  tableDataSourceArchived: MatTableDataSource<MorningNote>;

  // Training Links tab.
  trainingLinks = [];

  constructor(private dashService: DashboardService, private dialog: MatDialog, private authService: AuthService) { 
    // Not currently using Morning Meeting Notes tab.
    // this.subscription.add(this.dashService.getMorningMeetingNotes().subscribe(notes => {
    //   this.morningMeetingNotes = notes;
    //   this.tableDataSource = new MatTableDataSource(this.morningMeetingNotes);
    // }));

    // this.subscription.add(this.dashService.getArchivedMorningMeetingNotes().subscribe(notes => {
    //   this.archivedNotes = notes;
    //   this.tableDataSourceArchived = new MatTableDataSource(this.archivedNotes);
    // }));

    this.subscription.add(this.dashService.getTrainingLinks().subscribe(links => {
      this.trainingLinks = links;
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickAddButton() {
    this.dialog.open(AddMorningNoteComponent);
  }

  isSup() {
    return this.authService.isSup(this.authService.getCurrentUser());
  }

  getDisplayedColumns() {
    if (this.isSup() && this.editFlag) {
      return this.displayedColumnsWithEdit;
    } else {
      return this.displayedColumns;
    }
  }

  onEditButtonClicked(note: MorningNote) {
    this.dialog.open(AddMorningNoteComponent, {
      data: {note: note}
    });
  }

  onSendButtonClicked() {
    this.editFlag = false;

    setTimeout(() => {
      var data = document.getElementById('email-container');

      // Get date for filename.
      let d = new Date();
      let filename = "Morning Meeting Notes " + (d.getMonth() + 1) + "-" + (d.getDate() + 1) + "-" + d.getFullYear() + ".png";

      // Set width to a good readable size.
      data.style.width = "1000px";

      html2canvas(data).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/png');
        
        var link = document.createElement("a");
        link.download = filename;

        canvas.toBlob(function(blob){
          link.href = URL.createObjectURL(blob);
          console.log(blob);
          console.log(link.href);
          link.click();
        },'image/png');

        this.editFlag = true;
        data.style.width = "100%";
      });
    }, 1000);
  }

  onLinkButtonClicked(link: TrainingLink) {
    if (link) {
      this.dialog.open(TrainingLinkEditComponent, {
        data: {link: link}
      });
    } else {
      this.dialog.open(TrainingLinkEditComponent);
    }
  }
}

export interface DialogData {
  note: MorningNote;
}

export interface TrainingLinkDialogData {
  link: TrainingLink;
}
