<h2>Query weather data</h2>

<div id="query-container">

    <mat-select placeholder="Choose a query..." [(ngModel)]="querySelection">
        <mat-option value="table">Table</mat-option>
        <mat-option value="month">Month vs. historical</mat-option>
    </mat-select>

    <div id="selection-container" [ngSwitch]="querySelection">
        <app-table *ngSwitchCase="'table'" [nums]="historicalNums">Table</app-table>
        <app-month-over-month *ngSwitchCase="'month'" [nums]="historicalNums">Month vs. historical</app-month-over-month>
    </div>
</div>
