<h2>Add or edit historical data</h2>

<mat-dialog-content>
    <div id="content-container">
        <mat-form-field id="years-dropdown">
            <mat-select placeholder="Pick a year" name="year" (selectionChange)="onYearChanged($event)">
            <mat-option *ngFor="let year of historicalYears" [value]="year.id">
                {{ year.season }}
            </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-flat-button (click)="onAddYearClick()">Add season</button>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.preBase" placeholder="Pre-season base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.preTotal" placeholder="Pre-season total">
            <span matSuffix>"</span>
        </mat-form-field>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.novBase" placeholder="Nov base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.novTotal" placeholder="Nov total">
            <span matSuffix>"</span>
        </mat-form-field>
        
        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.decBase" placeholder="Dec base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.decTotal" placeholder="Dec total">
            <span matSuffix>"</span>
        </mat-form-field>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.janBase" placeholder="Jan base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.janTotal" placeholder="Jan total">
            <span matSuffix>"</span>
        </mat-form-field>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.febBase" placeholder="Feb base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.febTotal" placeholder="Feb total">
            <span matSuffix>"</span>
        </mat-form-field>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.marBase" placeholder="Mar base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.marTotal" placeholder="Mar total">
            <span matSuffix>"</span>
        </mat-form-field>

        <br>

        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.aprBase" placeholder="Apr base">
            <span matSuffix>"</span>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <input matInput type="number" [(ngModel)]="currentYear.aprTotal" placeholder="Apr total">
            <span matSuffix>"</span>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button color="primary" mat-raised-button (click)="onUpdateYearClick()">Update season</button>
    <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>    
