<h2>Daily Notes</h2>
<div id="container">
    <div id="notes-top-bar">
        <div id="new-note-entry" class="notes-top-bar">
            <input matInput placeholder="Enter a new note..." [(ngModel)]="newNote.text"
                    (keyup.enter)="addNote()">
        </div>
        <div id="period-menu" class="notes-top-bar">
            <button mat-icon-button [matMenuTriggerFor]="notesPeriodMenu">
                <mat-icon class="material-icons">date_range</mat-icon>
            </button>
            <mat-menu #notesPeriodMenu="matMenu">
                <ng-container *ngFor="let timePeriod of timePeriods">
                    <div class="time-period-menu-item">
                        <button class="time-period-menu-item-label" mat-menu-item 
                        (click)="onNewTimePeriodClicked(timePeriod.value)">
                            <mat-icon *ngIf="currentTimePeriod==timePeriod.value">done</mat-icon>
                            <span class="time-period-span">{{timePeriod.text}}</span>
                        </button>
                    </div>
                </ng-container>
            </mat-menu>
        </div>
    </div>
    <div id="list-container">
        <div id="in-progress-notes-container" class="notes-container">
            <div class="notes-subsection-title">In Progress</div>
            <ul id="in-progress-notes-list" class="notes-list">
                <li *ngFor="let note of incompleteNotes" >
                    <div id="in-progress-note" class="note-grid">
                        <mat-checkbox (click)="toggleNoteComplete(note)" [checked]="note.completed"></mat-checkbox>
                        <div style="display: flex; flex-direction: column; gap: 5px;">
                            <label>{{note.text}}</label>
                            <label style="font-size: 11px;">{{note.timeCreated | date: 'short'}}</label>
                        </div>
                        <button mat-icon-button class="delete-note-button" (click)="deleteNote(note)">
                            <mat-icon class="material-icons">close</mat-icon>
                        </button>
                    </div>
                </li>
            </ul>
            <div *ngIf="!hasInProgress" id="in-progress-no-notes" class="no-notes-text">No notes.</div>
        </div>
        <div id="completed-notes" class="notes-container">
            <div class="notes-subsection-title">Completed</div>
            <ul id="completed-notes-list" class="notes-list">
                <li *ngFor="let note of completedNotes" >
                    <div id="completed-note" class="note-grid">
                        <mat-checkbox (click)="toggleNoteComplete(note)" [checked]="note.completed"></mat-checkbox>
                        <div style="display: flex; flex-direction: column; gap: 5px;">
                            <label id="completed-note-text">{{note.text}}</label>
                            <label style="font-size: 11px;">{{note.timeCreated | date: 'medium'}}</label>
                        </div>
                        <label id="completed-note-inits">{{note.completersName}}</label>
                        <button mat-icon-button class="delete-note-button" (click)="deleteNote(note)">
                            <mat-icon class="material-icons">close</mat-icon>
                        </button>
                    </div>
                </li>
            </ul>
            <div *ngIf="!hasCompleted" id="completed-no-notes" class="no-notes-text">No notes.</div>
        </div>
    </div>
</div>
