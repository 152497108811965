import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueryService {

  constructor(private db: AngularFirestore) { }

  getCalloutsForPatroller(type: string, patrollerId: string, peak: string): Observable<any[]> {
    if (peak == "any") {
      return this.db.collection('records', ref => ref.where('respondingPatrollerId', '==', patrollerId)
                                              .where('type', '==', type)).valueChanges();
    } else {
      return this.db.collection('records', ref => ref.where('respondingPatrollerId', '==', patrollerId)
                                              .where('type', '==', type).where('peak', '==', peak)).valueChanges();
    }
    
  }
}
