import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { WeatherHistorical } from '../../objects/weather-historical';
import { WeatherService } from '../../services/weather.service';

@Component({
  selector: 'app-edit-historical',
  templateUrl: './edit-historical.component.html',
  styleUrls: ['./edit-historical.component.scss']
})
export class EditHistoricalComponent implements OnInit, OnDestroy {

  counterYear = 0;
  historicalYears: WeatherHistorical[] = [];
  currentYear = new WeatherHistorical();

  subscription = new Subscription();

  constructor(private weatherService: WeatherService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.subscription.add(this.weatherService.getWeatherConstants().subscribe(consts => {
      this.counterYear = consts[0].counter;
    }));

    this.subscription.add(this.weatherService.getHistoricalWeatherNums().subscribe(list => {
      this.historicalYears = list;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAddYearClick() {
    let newYear = new WeatherHistorical();
    let start = this.counterYear;
    let end = start + 1;
    newYear.id = start + "" + end;
    newYear.season = start + " - " + end;

    this.weatherService.addHistoricalWeatherYear(newYear)
    this.weatherService.updateWeatherConstants({counter: end});

    this.currentYear = newYear;
  }

  onUpdateYearClick() {
    this.weatherService.updateHistoricalWeatherYear(this.currentYear);
    this.snackbar.open('Season updated successfully', '', { duration: 2000, panelClass: ['success-snackbar'] });
  }

  onYearChanged(data: any) {
    this.currentYear = this.historicalYears.find(year => year.id == data.value);
  }

}
