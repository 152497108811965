<h2>New Record</h2>

<div id="record-detail-container">
  <form class="record-form">
    <div class="radio-group-container">
      <mat-radio-group [(ngModel)]="record.peak" name="peak" (change)="onPeakRadioButtonChange()" class="radio-group">
        <mat-radio-button value="Frontside" class="radio">Frontside</mat-radio-button>
        <mat-radio-button value="North" class="radio">North Peak</mat-radio-button>
        <mat-radio-button value="Outback" class="radio">Outback</mat-radio-button>
        <mat-radio-button value="Bergman" class="radio">Bergman</mat-radio-button>
      </mat-radio-group>
    </div>

    <br>

    <div *ngIf="record.covid == 'high'">
      <mat-form-field class="toboggan-number">
        <input matInput placeholder="Toboggan Number" [(ngModel)]="record.tobogganNumber" name="tobogganNumber">
      </mat-form-field>
      <br>
    </div>

    <mat-form-field class="name">
      <input matInput placeholder="Name" [(ngModel)]="record.name" name="name">
    </mat-form-field>

    <mat-radio-group [(ngModel)]="record.sex" name="sex">
      <mat-radio-button value="Male" class="radio">Male</mat-radio-button>
      <mat-radio-button value="Female" class="radio">Female</mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="full-width">
      <mat-select placeholder="Gear"
      [(ngModel)]="record.gear" name="gear">
        <mat-option *ngFor="let equip of equipments" [value]="equip.value">
          {{ equip.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <br>

    <mat-form-field class="top-clothing">
      <input matInput placeholder="Top color"
      [(ngModel)]="record.topColor" name="topColor">
    </mat-form-field>

    <span class="over-text">over</span>

    <mat-form-field class="bottom-clothing">
      <input matInput placeholder="bottom color"
      [(ngModel)]="record.bottomColor" name="bottomColor">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Chief Complaint" 
      [(ngModel)]="record.chiefComplaint" name="chiefComplaint">
    </mat-form-field>

    <mat-form-field class="full-width">
      <input matInput placeholder="Location" 
      [(ngModel)]="record.location" name="location">
    </mat-form-field>

    <mat-form-field class="full-width" *ngIf="record.type != '10-50' && record.type != 'Refusal' && record.type != 'Non-event'">
      <input matInput placeholder="Destination" 
      [(ngModel)]="record.destination" name="destination">
    </mat-form-field>

    <br>

    <div id="patroller-ai-group">
      <mat-form-field id="patroller-dropdown">
        <mat-select placeholder="Responding patroller"
        [(ngModel)]="record.respondingPatrollerId" name="respondingPatroller">
          <mat-option *ngFor="let patroller of patrollers" [value]="patroller.uid">
            {{ patroller.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-checkbox id="ai-checkbox" name="ai" [(ngModel)]="record.ai">AI</mat-checkbox>
    </div>
    
    <div class="radio-group-container">
      <mat-radio-group [(ngModel)]="record.type" name="type" class="radio-group" layout="row" (change)="onTypeRadioButtonChange()">
        <mat-radio-button value="10-50" class="radio">10-50</mat-radio-button>
        <mat-radio-button value="Taxi" class="radio">Taxi</mat-radio-button>
        <mat-radio-button value="Non-event" class="radio">Non-event</mat-radio-button>
        <mat-radio-button value="Refusal" class="radio">Refusal</mat-radio-button>
        <mat-radio-button value="Lost child" class="radio">Lost child</mat-radio-button>
        <mat-radio-button value="Missing guardian" class="radio">Missing guardian</mat-radio-button>
        <mat-radio-button value="Independent study" class="radio">Independent study</mat-radio-button>
      </mat-radio-group>
    </div>

    <br>

    <div class="button-container">
      <button mat-raised-button class="submit-button" id="submitRecordButton"
      color="primary" (click)="onSubmitButtonClick()">Submit</button>
      <button mat-raised-button class="clear-button" id="clearRecordButton"
      (click)="onClearButtonClick()">Clear</button>
      <button mat-raised-button id="moreInfoButton" 
      color="accent" (click)="onMoreInfoButtonClicked()">(+) More Info</button>
    </div>
  </form>
</div>