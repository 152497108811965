import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WeatherHistorical } from '../../objects/weather-historical';
import { WeatherService } from '../../services/weather.service';

@Component({
  selector: 'app-weather-query',
  templateUrl: './weather-query.component.html',
  styleUrls: ['./weather-query.component.scss']
})
export class WeatherQueryComponent implements OnInit, OnDestroy {

  historicalNums: WeatherHistorical[] = [];

  querySelection = "month";

  subscription = new Subscription();

  constructor(private weatherService: WeatherService) { }

  ngOnInit(): void {
    this.subscription.add(this.weatherService.getHistoricalWeatherNums().subscribe(nums => {

      // This mapping makes the objects in the destination list actual objects, not just similar objects.
      this.historicalNums = nums.map(x => Object.assign(new WeatherHistorical(), x));
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
