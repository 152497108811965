import { Component, OnInit } from '@angular/core';
import { ExpiredMed } from '../../../objects/expired-med';
import { ToolsService } from '../../../services/tools.service';

@Component({
  selector: 'app-add-expired-med',
  templateUrl: './add-expired-med.component.html',
  styleUrls: ['./add-expired-med.component.css']
})
export class AddExpiredMedComponent implements OnInit {

  report = new ExpiredMed();

  constructor(private toolsService: ToolsService) { }

  ngOnInit() {
  }

  onSubmitClicked() {
    this.toolsService.submitExpiredMedReport(this.report);
  }

}
