<mat-drawer-container class="dashboard-container">
    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Useful Links">
        <app-useful-links></app-useful-links>
      </mat-tab>
        <mat-tab *ngIf="false" label="Morning Meeting Notes"> 
            <div class="tab-container">
              <div *ngIf="isSup()" class="top-buttons">
                  <button mat-raised-button color="accent" (click)="onSendButtonClicked()">Download as image</button>
              </div>
                <div id="email-container">
                  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8">

                      <!-- Posted Column -->
                      <ng-container matColumnDef="posted">
                        <th mat-header-cell *matHeaderCellDef> Posted </th>
                        <td mat-cell *matCellDef="let note"> 
                          {{note.posted}} 
                        </td>
                      </ng-container>
                  
                      <!-- Expires Column -->
                      <ng-container matColumnDef="expires">
                        <th mat-header-cell *matHeaderCellDef> Expires </th>
                        <td mat-cell *matCellDef="let note"> {{note.expires}} </td>
                      </ng-container>
                  
                      <!-- Posted By Column -->
                      <ng-container matColumnDef="postedBy">
                        <th mat-header-cell *matHeaderCellDef> Posted By </th>
                        <td mat-cell *matCellDef="let note"> {{note.author}} </td>
                      </ng-container>
                  
                      <!-- Note Column -->
                      <ng-container matColumnDef="note">
                        <th mat-header-cell *matHeaderCellDef> Note </th>
                        <td mat-cell *matCellDef="let note">
                          <div class="note-text">{{note.text}}</div>
                        </td>
                      </ng-container>
                  
                      <!-- Edit Column -->
                      <ng-container matColumnDef="edit">
                          <th mat-header-cell *matHeaderCellDef>Edit</th>
                          <td mat-cell *matCellDef="let note"> 
                            <button *ngIf="isSup()" mat-icon-button (click)="onEditButtonClicked(note)">
                              <mat-icon class="material-icons">edit</mat-icon>
                            </button>
                          </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
                    </table>
                  </div>
                <div *ngIf="isSup()" class="bottom-buttons">
                    <button mat-raised-button color="primary" (click)="onClickAddButton()">Add (+)</button>
                </div>

                <div id="archive" *ngIf="isSup()" class="archived-notes">
                  <h3>Archived Notes</h3>
                  <table mat-table [dataSource]="tableDataSourceArchived" class="mat-elevation-z8">

                    <!-- Posted Column -->
                    <ng-container matColumnDef="posted">
                      <th mat-header-cell *matHeaderCellDef> Posted </th>
                      <td class="archive-cell" mat-cell *matCellDef="let note"> 
                        {{note.posted}} 
                      </td>
                    </ng-container>
                
                    <!-- Expires Column -->
                    <ng-container matColumnDef="expires">
                      <th mat-header-cell *matHeaderCellDef> Expires </th>
                      <td class="archive-cell" mat-cell *matCellDef="let note"> {{note.expires}} </td>
                    </ng-container>
                
                    <!-- Posted By Column -->
                    <ng-container matColumnDef="postedBy">
                      <th mat-header-cell *matHeaderCellDef> Posted By </th>
                      <td class="archive-cell" mat-cell *matCellDef="let note"> {{note.author}} </td>
                    </ng-container>
                
                    <!-- Note Column -->
                    <ng-container matColumnDef="note">
                      <th mat-header-cell *matHeaderCellDef> Note </th>
                      <td class="archive-cell" mat-cell *matCellDef="let note">
                        <div class="note-text">{{note.text}}</div>
                      </td>
                    </ng-container>
                
                    <!-- Edit Column -->
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell *matHeaderCellDef>Edit</th>
                        <td class="archive-cell" mat-cell *matCellDef="let note"> 
                          <button *ngIf="isSup()" mat-icon-button (click)="onEditButtonClicked(note)">
                            <mat-icon class="material-icons">edit</mat-icon>
                          </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
                  </table>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Current Trainings"> 
            <div class="tab-container">
              <h4>Trainings to complete</h4>
              <ul id="links-list">
                <li *ngFor="let link of trainingLinks" class="hoverable">
                  <a mat-button href="{{link.link}}" color="primary" target="_blank" rel="noopener">{{link.text}}</a>
                  <button *ngIf="isSup()" class="hover-buttons" mat-icon-button (click)="onLinkButtonClicked(link)">
                    <mat-icon class="material-icons">edit</mat-icon>
                  </button>
                </li>
              </ul>
              <div *ngIf="isSup()" class="bottom-buttons">
                <button mat-raised-button color="primary" (click)="onLinkButtonClicked(null)">Add (+)</button>
              </div>
            </div>
        </mat-tab>
        <mat-tab label="Medical Minute">
          <app-med-minute></app-med-minute>
        </mat-tab>
    </mat-tab-group>
</mat-drawer-container>
