<div id="header">
    <mat-slide-toggle [(ngModel)]="showBases">Show settled bases</mat-slide-toggle>
</div>

<div id="table-container">
    <table mat-table [dataSource]="nums" class="mat-elevation-z8">

        <ng-container matColumnDef="season" sticky>
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let season"> {{season.season}} </td>
        </ng-container>

        <ng-container matColumnDef="pre">
        <th mat-header-cell *matHeaderCellDef> Pre-season </th>
        <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.preTotal)"> {{season.preTotal}} </td>
        </ng-container>

        <ng-container matColumnDef="nov">
            <th mat-header-cell *matHeaderCellDef> November </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.novTotal)"> {{season.novTotal}}<span *ngIf="showBases"> / {{season.novBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="dec">
            <th mat-header-cell *matHeaderCellDef> December </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.decTotal)"> {{season.decTotal}}<span *ngIf="showBases"> / {{season.decBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="jan">
            <th mat-header-cell *matHeaderCellDef> January </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.janTotal)"> {{season.janTotal}}<span *ngIf="showBases"> / {{season.janBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="feb">
            <th mat-header-cell *matHeaderCellDef> February </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.febTotal)"> {{season.febTotal}}<span *ngIf="showBases"> / {{season.febBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="mar">
            <th mat-header-cell *matHeaderCellDef> March </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.marTotal)"> {{season.marTotal}}<span *ngIf="showBases"> / {{season.marBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="apr">
            <th mat-header-cell *matHeaderCellDef> April </th>
            <td mat-cell *matCellDef="let season" [class]="season.getFontClass(season.aprTotal)"> {{season.aprTotal}}<span *ngIf="showBases"> / {{season.aprBase}}</span></td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> Season </th>
            <td mat-cell *matCellDef="let season"> {{season.getSeasonTotal()}}</td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>