import { Component, OnChanges, ViewChild, Input } from '@angular/core';
import { BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DailyWeatherObservation } from '../../../objects/daily-weather-observation';


@Component({
  selector: 'app-bp-storms',
  templateUrl: './bp-storms.component.html',
  styleUrls: ['./bp-storms.component.css']
})
export class BpStormsComponent implements OnChanges {

  lineChartData: ChartDataSets[];
  lineChartLabels: Label[];
  lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'bp',
          position: 'left',
          scaleLabel: { display: true, labelString: 'Barometric pressure (inches Hg)' }
        },
        {
          id: 'snow',
          position: 'right',
          scaleLabel: { display: true, labelString: 'Snow (inches)' }
        }
      ]
    },
    legend: { display: false }
  };
  
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() nums: DailyWeatherObservation[];

  ngOnChanges() {
    this.updateDataForChart();
  }

  updateDataForChart() {
    let bpSeries = [];
    let snowSeries = [];
    let labels = [];

    this.nums.forEach(ob => {
      bpSeries.push(ob.barometricPressure);
      snowSeries.push(ob.npHn);
      labels.push(new Date(ob.timestamp).toLocaleDateString());
    });

    this.lineChartLabels = labels;

    this.lineChartData = [
      { data: bpSeries },
      { data: snowSeries, yAxisID: 'snow', lineTension: 0.0, steppedLine: 'middle' }
    ];
  }
}
