<div class="title-container">
  <h2>{{ peakName }} {{openingOrClosingName}}</h2>
  <mat-form-field>
    <input matInput [matDatepicker]="openingsDatePicker" 
    placeholder="Choose a date" [(ngModel)]="date"
    (dateChange)="onDateChanged()">
    <mat-datepicker-toggle matSuffix [for]="openingsDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #openingsDatePicker></mat-datepicker>
  </mat-form-field>
</div>

<div class="radio-group-container">
  <mat-radio-group [(ngModel)]="typeOfFrontsideSweeps" name="type" class="radio-group" 
  *ngIf="isClosingAndFrontside()" layout="row">
    <mat-radio-button value="Day" class="radio" (change)="onRadioChange($event)">Day</mat-radio-button>
    <mat-radio-button value="Night" class="radio" (change)="onRadioChange($event)">Night</mat-radio-button>
  </mat-radio-group>
</div>

<div class="table-container">
  <table *ngIf="isOpening" align="center">
    <tr *ngFor="let opening of openingRecords | openingFilter: true">
      <td><div class="opening-text">{{ opening.text }}</div></td>
      <td class="patroller-cells">
        <mat-select [disabled]="!isDispatch()" placeholder="Patroller"
        [(ngModel)]="opening.patrollerId" name="patroller">
          <mat-option *ngFor="let patroller of patrollers" [value]="patroller.uid">
            {{ patroller.displayName }}
          </mat-option>
        </mat-select>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="isOpening" class="expired-meds">
  <button (click)="onExpiredMedsClick()" mat-flat-button >Got expired med equipment? Click here</button>
</div>

<div class="table-container">
  <table id="openings-table" [style.border-color]="hasUnsubmittedChanges ? 'red' : 'black'" align="center">
    <tr>
      <th>{{openingOrClosingHeaderName}}</th>
      <th *ngIf="editMode"></th>
      <th *ngIf="editMode">Order</th>
      <th>Patroller</th>
      <th class="not-mobile">Notes</th>
    </tr>
    <tr *ngFor="let opening of openingRecords | openingFilter: false">
      
      <td>
        <div *ngIf="opening.header">
          <div class="closing-header-text">{{ opening.text }}</div>
        </div>
        <div *ngIf="!opening.header" class="opening-text">{{ opening.text }}</div>
      </td>
      <td *ngIf="editMode">
        <button id="edit-button" mat-icon-button (click)="onEditButtonClicked(opening)">
          <mat-icon class="material-icons">edit</mat-icon>
        </button>
      </td>
      <td *ngIf="editMode">{{opening.order}}</td>

      <td class="patroller-cells">
        <mat-select *ngIf="!opening.header" [disabled]="!isDispatch()" placeholder="Patroller"
        [(ngModel)]="opening.patrollerId" name="patroller" (selectionChange)="onOpeningValueChanged()">
          <mat-option *ngFor="let patroller of patrollers" [value]="patroller.uid">
            {{ patroller.displayName }}
          </mat-option>
        </mat-select>
      </td>
      <td class="not-mobile">
        <textarea *ngIf="!opening.header" class="notes-input" matInput placeholder="..." [(ngModel)]="opening.notes" (change)="onOpeningValueChanged()"></textarea>
      </td>
    </tr>
  </table>
</div>

<div class="centered-container" *ngIf="isDispatch()">
  <button mat-raised-button class="submit-button" id="submitRecordButton"
  color="primary" (click)="onSubmitButtonClicked()">Submit</button>
  <button mat-raised-button color="gray" 
  (click)="onClearButtonClicked()">Clear</button>
  <button mat-raised-button color="accent" *ngIf="editMode"
  (click)="onAddButtonClicked()">Add</button>
</div>

<div class="centered-container" *ngIf="isAdmin()">
  <mat-slide-toggle id="edit-toggle" 
  [(ngModel)]="editMode" 
  color="primary">
    Edit mode
  </mat-slide-toggle>
</div>
