  <h2>Records</h2>

  <div class="date-picker-container">
    <mat-form-field>
      <input matInput [matDatepicker]="recordsDatePicker" 
      placeholder="Choose a date" [(ngModel)]="date"
      (dateChange)="onDateChanged()">
      <mat-datepicker-toggle matSuffix [for]="recordsDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #recordsDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div *ngIf="records.length > 0; else noRecords" class="records-container">
    <ul class="records-list">
      <li *ngFor="let record of records"
      [ngClass]="[record.type, record.peak]">
      <div [style.background-color]="record.is1033 ? '#ef9a9a' : 'clear'" id="record-row">
        <div [style.background-color]="record.traumaActivated ? '#F44336' : 'clear'" id="record-column-0">
          {{record.typeLabel}}
        </div>
        <div id="record-column-1" (click)="onRecordClick(record)"> 
          <div class="grid-container">
            <span class="info-label">Name</span><span>{{record.name}}</span>
            <span class="info-label">Sex</span><span>{{record.sex}}</span>
            <span class="info-label">Gear</span><span>{{record.gear}}</span>
            <span class="info-label">Clothing</span><span>{{record.topColor}}/{{record.bottomColor}}</span>
            
            <span class="info-label">Complaint</span><span class="long-row">{{record.chiefComplaint}}</span>
            <span class="info-label">Location</span><span class="long-row">{{record.location}}</span>
            <span *ngIf="record.destination" class="info-label">Destination</span><span *ngIf="record.destination" class="long-row">{{record.destination}}</span>
            <span class="info-label">Patroller</span><span class="medium-row">{{ patrollers | patrollerFilter: record.respondingPatrollerId }}</span>
            <div *ngIf="record.hasSecondaryInfo" class="indicator"><b>*more info</b></div>
          </div>
          <div class="time-label">{{record.timeReportedString}}</div>
        </div>  
        <div id="record-column-2"> 
          <button mat-icon-button [matMenuTriggerFor]="recordEditMenu">
            <mat-icon class="material-icons">more_vert</mat-icon>
          </button>
          <mat-menu #recordEditMenu="matMenu">
            <button mat-menu-item (click)="onEditTimeButtonClicked(record)">
              <mat-icon>edit</mat-icon>
              <span>Edit time</span>
            </button>
            <button mat-menu-item (click)="onDeleteButtonClicked(record)">
              <mat-icon>clear</mat-icon>
              <span>Delete</span>
            </button>
            <button *ngIf="!record.traumaActivated && !record.is1033" mat-menu-item (click)="onTraumaActivationButtonClicked(record)">
              <mat-icon>accessibility_new</mat-icon>
              <span>Trauma Activate</span>
            </button>
            <button *ngIf="record.traumaActivated" mat-menu-item (click)="onTraumaActivationButtonClicked(record)">
              <mat-icon>replay</mat-icon>
              <span>Trauma Deactivate</span>
            </button>
            <button class="button1033" *ngIf="!record.is1033" mat-menu-item (click)="on1033ButtonClicked(record)">
              <mat-icon>trending_down</mat-icon>
              <span>10-33</span>
            </button>
            <button class="button1033" *ngIf="record.is1033" mat-menu-item (click)="goTo1033(record)">
              <mat-icon>open_in_new</mat-icon>
              <span>Go to 10-33 form</span>
            </button>
            <button *ngIf="record.is1033" mat-menu-item (click)="on1033ButtonClicked(record)">
              <mat-icon>replay</mat-icon>
              <span>Not a 10-33</span>
            </button>
          </mat-menu>
        </div>
      </div>  
      </li>
    </ul>
  </div>

  <div class="totals-container">
    <br>  
    <b>Today's summary: </b>
    <br>
    <b>{{ totalCallouts }}</b> total call-out<span *ngIf="totalCallouts !== 1">s</span>
    <br>
    <b>{{ total1050s }}</b> 10-50<span *ngIf="total1050s !== 1">'s</span>
    <br>
    <b>{{ totalTaxis }}</b> taxi<span *ngIf="totalTaxis !== 1">s</span>
    <br>
    <b>{{ totalRefusals }}</b> refusal<span *ngIf="totalRefusals !== 1">s</span>
    <br>
    <b>{{ totalNonEvents }}</b> non-event<span *ngIf="totalNonEvents !== 1">s</span>
  </div>

  <ng-template #noRecords>
    <p style="text-align: center">No records.</p>
  </ng-template>
