import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { DailyWeatherObservation } from '../../../objects/daily-weather-observation';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnChanges {

  lineChartData: ChartDataSets[];
  lineChartLabels: Label[];
  lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'temp',
          position: 'left',
          scaleLabel: { display: true, labelString: 'Temp (°F)' },
          ticks: {

          }
        },
        {
          id: 'snow',
          position: 'right',
          scaleLabel: { display: true, labelString: 'Snow (inches)' },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() nums: DailyWeatherObservation[];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.updateDataForChart();
  }

  updateDataForChart() {
    let lowTempSeries = [];
    let highTempSeries = [];
    let hsSeries = [];
    let hnSeries = [];
    let labels = [];

    this.nums.forEach(ob => {
      lowTempSeries.push(ob.low24);
      highTempSeries.push(ob.high24);
      hsSeries.push(ob.npHs);
      hnSeries.push(ob.npHn);
      labels.push(new Date(ob.timestamp).toLocaleDateString());
    });

    this.lineChartLabels = labels;

    this.lineChartData = [
      { data: highTempSeries, label: 'High', fill: false },
      { data: lowTempSeries, label: 'Low', fill: false },
      { data: hsSeries, label: 'NP base', yAxisID: 'snow', steppedLine: 'middle' },
      { data: hnSeries, label: 'NP new', yAxisID: 'snow', steppedLine: 'middle', showLine: false, pointBorderColor: '#666666', pointBackgroundColor: '#666666', pointStyle: 'star' }
    ];
  }
}
