import { Component, OnInit, Inject } from '@angular/core';
import { TrainingLink } from '../../../objects/training-link';
import { DashboardService } from '../../../services/dashboard.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrainingLinkDialogData } from '../../dashboard/dashboard.component';

@Component({
  selector: 'app-training-link-edit',
  templateUrl: './training-link-edit.component.html',
  styleUrls: ['./training-link-edit.component.css']
})
export class TrainingLinkEditComponent implements OnInit {

  link = new TrainingLink();

  constructor(private dashService: DashboardService, @Inject(MAT_DIALOG_DATA) private data: TrainingLinkDialogData) {
    if (this.data) {
      this.link = data.link;
    }
  }

  ngOnInit() {
  }

  onSubmitClicked() {
    this.dashService.addOrUpdateTrainingLink(this.link);
  }

  onDeleteClicked() {
    this.dashService.deleteTrainingLink(this.link);
  }

}
