<h2>Trends</h2>

<div id="trends-container">
    <mat-form-field id="trend-picker">
        <mat-label>Trend</mat-label>
        <mat-select placeholder="Choose a trend..." [(ngModel)]="querySelection">
            <mat-option value="summary">Summary</mat-option>
            <mat-option value="peaks">Peak settled base comparison</mat-option>
            <mat-option value="bp">Barometric pressure vs. storms</mat-option>
        </mat-select>
    </mat-form-field>
    
    <div id="date-field">
        <mat-form-field id="date-picker">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                <input matEndDate placeholder="End date" [(ngModel)]="endDate">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    
        <button id="update-button" mat-raised-button color="primary" (click)="onUpdateRangeClick()">Update</button>

        <div id="date-ranges">
            <button mat-stroked-button (click)="rangeButtonClick('mtd')">Month-to-date</button>
            <button mat-stroked-button (click)="rangeButtonClick('std')">Season-to-date</button>
            <button mat-stroked-button (click)="rangeButtonClick('30')">Last 30 days</button>
        </div>
      
    </div>
    
    <div class="chart-container">
        <div id="selection-container" [ngSwitch]="querySelection">
            <app-summary *ngSwitchCase="'summary'" [nums]="weatherObsRange"></app-summary>
            <app-peaks-comparison *ngSwitchCase="'peaks'" [nums]="weatherObsRange"></app-peaks-comparison>
            <app-bp-storms *ngSwitchCase="'bp'" [nums]="weatherObsRange"></app-bp-storms>
        </div>
    </div>    
</div>
    