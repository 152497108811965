<div class="container">
  <mat-sidenav-container class="side-menu-container">
    <mat-sidenav #sidenav>
      <app-side-menu (onLinkClicked)="sidenav.toggle()"></app-side-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="site-header">
        <div class="header-element header-button-start">
          <button *ngIf="!isPublic" mat-icon-button (click)="sidenav.toggle()">
            <mat-icon class="material-icons">menu</mat-icon>
          </button>
        </div>
        <div class="title-text header-element">{{ title }}</div>
        <div class="header-element header-button-end">
          <button *ngIf="!isPublic" mat-icon-button>
            <a routerLink="weather"><mat-icon class="material-icons">wb_sunny</mat-icon></a>
          </button>
          <button *ngIf="!isPublic" mat-icon-button>
            <a routerLink="dashboard"><mat-icon class="material-icons">home</mat-icon></a>
          </button>
        </div>
      </div>
      <router-outlet></router-outlet>
      <footer align="center">
        <p>&copy;Keystone Ski Patrol 2018-2021</p>
        <img *ngIf="!isPublic" src="./assets/ksp_logo.png" width="100px">
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
