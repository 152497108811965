import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  mode: string;
  oob: string;
  newPasswordForm: FormGroup;
  passwordsMatch = true;

  formErrors = {
    'email': '',
    'password': '',
    'phone': ''
  };

  validationMessages = {
    'password': {
      'required':      'Password is required.',
      'pattern':       'Password must include at least one letter and one number.',
      'minlength':     'Password must be at least 6 characters long.',
      'maxlength':     'Password cannot be more than 25 characters long.',
    }
  };

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService, private fb: FormBuilder) { }

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.queryParams['mode'];
    this.oob = this.activatedRoute.snapshot.queryParams['oobCode'];
    console.log("mode: " + this.mode + "\ncode: " + this.oob);
    this.buildForms();
  }

  onResetClick() {
    let password = this.newPasswordForm.get("password").value;
    if (password != this.newPasswordForm.get("confirmedPassword").value) {
      this.passwordsMatch = false;
      return;
    } else {
      this.passwordsMatch = true;
    }

    this.authService.resetPassword(this.oob, password).then(() => {
      // Success.
      console.log("Success");
      this.router.navigate(['login']);
    }, (err) => {
      // Error.
      console.log("error confirming pw reset: " + err);
    });
  }


  // Forms shenanigans.
  buildForms(): void {
    this.newPasswordForm = this.fb.group({
      'password': ['', [
        Validators.pattern('(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z\d$@$!%*?&].{6,25}'),
        Validators.minLength(6),
        Validators.maxLength(25)
        ]
      ],
      'confirmedPassword': []});

    this.newPasswordForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages.
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.newPasswordForm) { return; }
    const form = this.newPasswordForm;
    for (const field in this.formErrors) {
      // Clear previous error message.
      this.formErrors[field] = '';
      const control = form.get(field);
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

}
