<form [formGroup]="newPasswordForm" class="pw-form">
    
    <h2>Reset password</h2>    
    <mat-form-field class="text-input">
        <input matInput type="password" name="password" placeholder="Password" 
        formControlName="password" required>
    </mat-form-field>
    <div class="form-errors" *ngIf="formErrors.password">
      {{ formErrors.password }}
    </div>
    <br>
    <mat-form-field class="text-input">
        <input matInput type="password" name="confirmedPassword" placeholder="Confirm password" 
        formControlName="confirmedPassword" required>
    </mat-form-field>
    <div class="form-errors" *ngIf="!passwordsMatch">
      Passwords do not match.
    </div>
    <br>
    <button mat-raised-button color="primary" (click)="onResetClick()" [disabled]="!newPasswordForm.valid">Submit</button>
    <br>
    <br>
    <div>Hey you might want to write it down this time.</div>
  </form>