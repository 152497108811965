import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpiredMed } from '../../objects/expired-med';
import { ToolsService } from '../../services/tools.service';
import * as moment from 'moment';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../objects/user';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-expired-meds',
  templateUrl: './expired-meds.component.html',
  styleUrls: ['./expired-meds.component.css']
})
export class ExpiredMedsComponent implements OnInit, OnDestroy, AfterViewInit {

  incompleteExpiredMeds: ExpiredMed[];
  completeExpiredMeds: ExpiredMed[];
  hasIncomplete: boolean = false;
  hasComplete: boolean = false;

  date: moment.Moment = moment();
  user: User;

  subscription = new Subscription();

  displayedColumns: string[] = ['check', 'peak', 'packType', 'packNumber', 'med', 'status', 'quantity', 'delete'];
  displayedColumnsCompleted: string[] = ['check', 'peak', 'packType', 'packNumber', 'med', 'status', 'quantity', 'completedBy', 'delete'];
  @ViewChild(MatSort, {static: false}) incompleteSort: MatSort;
  incompleteDataSource;
  completeDataSource;
  
  constructor(private toolsService: ToolsService, public authService: AuthService) { }

  ngOnInit() {
    this.subscription.add(this.authService.user$.subscribe(user => {
      this.user = user;
    }));
    this.user = this.authService.getCurrentUser();
    this.getReports();
  }
  
  ngAfterViewInit() {
    
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getReports() {
    this.subscription.add(this.toolsService.getIncompleteExpiredMedReports().subscribe(incompletes => {
      this.incompleteExpiredMeds = incompletes;
      this.incompleteDataSource = new MatTableDataSource(this.incompleteExpiredMeds);
      this.incompleteDataSource.sort = this.incompleteSort;
      this.hasIncomplete = this.incompleteExpiredMeds.length > 0;
    }));

    this.subscription.add(this.toolsService.getCompleteExpiredMedReportsInLastSevenDays().subscribe(completes => {
      this.completeExpiredMeds = completes;
      this.completeDataSource = new MatTableDataSource(this.completeExpiredMeds);
      this.hasComplete = this.completeExpiredMeds.length > 0;
    }));
  }

  toggleReportComplete(report: ExpiredMed) {
    report.completed = !report.completed;
    
    // If, after updating, it's complete, add user's initials.
    if (report.completed) {
      report.completer = this.user.displayName;
      report.dateCompleted = new Date().getTime();
    }
    this.toolsService.submitExpiredMedReport(report);
  }

  deleteReport(report: ExpiredMed) {
    this.toolsService.deleteExpiredMedReport(report);
  }
}
