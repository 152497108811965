<h2>Missing or expired medical supplies</h2>
<div id="container">
    <h3>To replace</h3>
    <table mat-table [dataSource]="incompleteDataSource" class="mat-elevation-z8" matSort>

        <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let report"> 
                <mat-checkbox (click)="toggleReportComplete(report)" [checked]="report.completed"></mat-checkbox>
            </td>
        </ng-container>
      
        <ng-container matColumnDef="peak">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Peak </th>
            <td mat-cell *matCellDef="let report"> {{report.peak}} </td>
        </ng-container>

        <ng-container matColumnDef="packType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pack Type </th>
            <td mat-cell *matCellDef="let report"> {{report.packType}} </td>
        </ng-container>

        <ng-container matColumnDef="packNumber">
            <th mat-header-cell *matHeaderCellDef> Pack Number </th>
            <td mat-cell *matCellDef="let report"> {{report.packNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="med">
            <th mat-header-cell *matHeaderCellDef> Med/Supply </th>
            <td mat-cell *matCellDef="let report"> {{report.med}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let report"> {{report.status}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let report"> {{report.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let report">
                <button mat-icon-button class="delete-report-button" (click)="deleteReport(report)">
                    <mat-icon class="material-icons">close</mat-icon>
                </button>
            </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <h3>Completed</h3>
      <table mat-table [dataSource]="completeDataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="check">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let report"> 
                <mat-checkbox (click)="toggleReportComplete(report)" [checked]="report.completed"></mat-checkbox>
            </td>
        </ng-container>
      
        <ng-container matColumnDef="peak">
            <th mat-header-cell *matHeaderCellDef> Peak </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.peak}} </td>
        </ng-container>

        <ng-container matColumnDef="packType">
            <th mat-header-cell *matHeaderCellDef> Pack Type </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.packType}} </td>
        </ng-container>

        <ng-container matColumnDef="packNumber">
            <th mat-header-cell *matHeaderCellDef> Pack Number </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.packNumber}} </td>
        </ng-container>

        <ng-container matColumnDef="med">
            <th mat-header-cell *matHeaderCellDef> Med/Supply </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.med}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.status}} </td>
        </ng-container>

        <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td class="completed-report-text" mat-cell *matCellDef="let report"> {{report.quantity}} </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td class="completed-report-text" mat-cell *matCellDef="let report">
                <button mat-icon-button class="delete-report-button" (click)="deleteReport(report)">
                    <mat-icon class="material-icons">close</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="completedBy">
            <th mat-header-cell *matHeaderCellDef> Completed by </th>
            <td class="completed-report-inits" mat-cell *matCellDef="let report"> {{report.completer}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumnsCompleted"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsCompleted;"></tr>
      </table>
      
</div>
