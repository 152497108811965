export class WeatherHistorical {
    id: string;
    season: string;
    
    preBase: number;
    novBase: number;
    decBase: number;
    janBase: number;
    febBase: number;
    marBase: number;
    aprBase: number;

    preTotal: number = 0;
    novTotal: number = 0;
    decTotal: number = 0;
    janTotal: number = 0;
    febTotal: number = 0;
    marTotal: number = 0;
    aprTotal: number = 0;

    getSeasonTotal(): number {
        return this.preTotal + this.novTotal + this.decTotal + this.janTotal + this.febTotal + this.marTotal + this .aprTotal;
    }

    novToDate(): number {
        return this.preTotal + this.novTotal;
    }

    decToDate(): number {
        return this.preTotal + this.novTotal + this.decTotal;
    }

    janToDate(): number {
        return this.preTotal + this.novTotal + this.decTotal + this.janTotal;
    }

    febToDate(): number {
        return this.preTotal + this.novTotal + this.decTotal + this.janTotal + this.febTotal;
    }

    marToDate(): number {
        return this.preTotal + this.novTotal + this.decTotal + this.janTotal + this.febTotal + this.marTotal;
    }

    getFontClass(val: number): string {
        let str = "";

        if (val < 15) {
            str = "extra-low";
        } else if (val < 30) {
            str = "low";
        } else if (val < 45) {
            str = "normal";
        } else if (val < 60) {
            str = "high";
        } else if (val < 85) {
            str = "extra-high";
        } else {
            str = "heavy";
        }

        return str;
    }
}
