import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-med-minute',
  templateUrl: './med-minute.component.html',
  styleUrls: ['./med-minute.component.css']
})
export class MedMinuteComponent implements OnInit {

  pdfSrc = "";
  pdf2Src = "";
  fileToUpload = null;
  file2ToUpload = null;
  uploadProgress: Observable<number>;
  uploadProgress2: Observable<number>;
  currentUpload = false;
  currentUpload2 = false;

  constructor(private dashService: DashboardService, private authService: AuthService) { }

  ngOnInit() {
    this.downloadPdfFromFirebase();
    this.downloadPdf2FromFirebase();
  }

  isSpecialist(): boolean {
    return this.authService.isSpecialist(this.authService.getCurrentUser());
  }

  updateFile(event) {
    this.fileToUpload = event.target.files[0];
  }

  updateFile2(event) {
    this.file2ToUpload = event.target.files[0];
  }

  upload() {
    this.currentUpload = true;
    let task = this.dashService.uploadNewMedMinuteDoc(this.fileToUpload, 'med-minute/doc.pdf');
    this.uploadProgress = task.percentageChanges();
    this.uploadProgress.subscribe(num => {
      if (num == 100) {
        this.downloadPdfFromFirebase();
        setTimeout(() => {
          this.currentUpload = false;
        }, 1000);
      }
    });
  }

  upload2() {
    this.currentUpload2 = true;
    let task = this.dashService.uploadNewMedMinuteDoc(this.file2ToUpload, 'med-minute/cal.pdf');
    this.uploadProgress2 = task.percentageChanges();
    this.uploadProgress2.subscribe(num => {
      if (num == 100) {
        this.downloadPdf2FromFirebase();
        setTimeout(() => {
          this.currentUpload2 = false;
        }, 1000);
      }
    });
  }

  downloadPdfFromFirebase() {
    this.dashService.getMedMinutePdf('med-minute/doc.pdf').pipe(take(1)).subscribe(url => {
      this.pdfSrc = url;
    });
  }

  downloadPdf2FromFirebase() {
    this.dashService.getMedMinutePdf('med-minute/cal.pdf').pipe(take(1)).subscribe(url => {
      this.pdf2Src = url;
    });
  }
}
