import { Component, OnInit, Inject } from '@angular/core';
import { MorningNote } from '../../../objects/morning-note';
import { DashboardService } from '../../../services/dashboard.service';
import { Moment } from 'moment';
import { DialogData } from '../../dashboard/dashboard.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-morning-note',
  templateUrl: './add-morning-note.component.html',
  styleUrls: ['./add-morning-note.component.css']
})
export class AddMorningNoteComponent implements OnInit {

  note = new MorningNote();
  expiryDate: Moment;

  constructor(private dashService: DashboardService, @Inject(MAT_DIALOG_DATA) private data: DialogData) {
    if (this.data) {
      this.note = this.data.note;
    }
  }

  ngOnInit() {
  }

  onSubmitClicked() {
    // For new notes.
    if (!this.note.posted) {
      // Make current date plus one "Posted" date. 
      // Plus one because the sups send it out the day before.
      let d = new Date();
      this.note.posted = (d.getMonth() + 1) + "/" + (d.getDate() + 1) + "/" + d.getFullYear();
    }

    // Convert date picker to readable string. (This should happen for edited notes in case the expiry date was changed.)
    this.note.expires = (this.note.expiryMoment.month() + 1) + "/" + this.note.expiryMoment.date() + "/" + this.note.expiryMoment.year();

    // Post.
    this.dashService.addOrUpdateMorningMeetingNote(this.note);
  }

  onDeleteClicked() {
    this.dashService.deleteMorningMeetingNote(this.note);
  }

  onArchiveClicked() {
    this.dashService.archiveMorningMeetingNote(this.note);
  }
}
