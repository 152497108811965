import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TransactionService } from './transaction.service';
import { Observable } from 'rxjs';
import { MorningNote } from '../objects/morning-note';
import { TrainingLink } from '../objects/training-link';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private db: AngularFirestore, private transactionService: TransactionService, private storage: AngularFireStorage) { }

  getMorningMeetingNotes(): Observable<any[]> {
    return this.db.collection('morning-meeting-notes', ref => ref.where('archived', '==', false).orderBy('expiryMoment', 'desc')).valueChanges();
  }

  getArchivedMorningMeetingNotes(): Observable<any[]> {
    return this.db.collection('morning-meeting-notes', ref => ref.where('archived', '==', true).orderBy('expiryMoment', 'desc')).valueChanges();
  }

  addOrUpdateMorningMeetingNote(note: MorningNote) {
    
    // If new note, assign an ID. Otherwise, use existing ID.
    if (!note.id) {
      note.id = this.db.createId();
    }
    
    let post = JSON.parse(JSON.stringify(note));
    this.transactionService.writeDataToDocForCollection(post, note.id, 'morning-meeting-notes');
  }

  archiveMorningMeetingNote(note: MorningNote) {
    note.archived = true;
    let post = JSON.parse(JSON.stringify(note));
    this.transactionService.writeDataToDocForCollection(post, note.id, 'morning-meeting-notes');
  }

  deleteMorningMeetingNote(note: MorningNote) {
    this.transactionService.deleteDocInCollection(note.id, 'morning-meeting-notes', note);
  }

  getTrainingLinks(): Observable<any[]> {
    return this.db.collection('training-links').valueChanges();
  }

  addOrUpdateTrainingLink(link: TrainingLink) {
    if (!link.id) {
      link.id = this.db.createId();
    }

    let post = JSON.parse(JSON.stringify(link));
    this.transactionService.writeDataToDocForCollection(post, link.id, 'training-links');
  }

  deleteTrainingLink(link: TrainingLink) {
    this.transactionService.deleteDocInCollection(link.id, 'training-links', link);
  }

  // Medical Minute.
  getMedMinutePdf(name: string): Observable<any> {
    let path = this.storage.ref(name);
    return path.getDownloadURL();
  }

  uploadNewMedMinuteDoc(file: File, name: string) {
    let ref = this.storage.ref(name);
    let task = ref.put(file);
    return task;
  }
}
