<h2 mat-dialog-title>Edit {{openingOrClosing}}</h2>
<mat-dialog-content>
  <div id="content-container">
    <mat-form-field *ngIf="isNew">
      <input matInput placeholder="ID" name="id" [(ngModel)]="opening.id">
    </mat-form-field>
    <div *ngIf="isNew" class="id-descrip">The ID should be all lowercase, concise, and contain no spaces (can use a dash to separate words). E.g. the ID for Zuma Lift Line, Modest Girl, Zuma West could simply be 'zuma-lift-line'.</div>
    <mat-form-field>
      <input matInput placeholder="Text" name="text" [(ngModel)]="opening.text">
    </mat-form-field>
    <br>
    <mat-form-field>
      <input matInput placeholder="Order" name="order" type="number" [(ngModel)]="opening.order">
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Peak" name="peak" [(ngModel)]="opening.peak">
        <mat-option value="frontside">Frontside</mat-option>
        <mat-option value="north-peak">North Peak</mat-option>
        <mat-option value="outback">Outback</mat-option>
        <mat-option value="bergman">Bergman</mat-option>
        <mat-option value="summer">Summer Ops</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox *ngIf="openingOrClosing == 'closings' && isNew && opening.peak == 'frontside'" [(ngModel)]="opening.day">Day sweep</mat-checkbox>
    <br>
    <mat-checkbox *ngIf="openingOrClosing == 'closings' && isNew && opening.peak == 'frontside'" [(ngModel)]="opening.night">Night sweep</mat-checkbox>
    <br>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onSubmitClicked()" mat-dialog-close="true">Submit</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" (click)="onDeleteClicked()" mat-dialog-close>Delete</button>
</mat-dialog-actions>