<h2>Menu</h2>
<div id="border-line"></div>
<ul class="side-menu">
  <li class="clickable">
    <a routerLink="/dashboard" (click)="linkClicked()" id="dashboardSideMenuLink">Dashboard</a>
  </li>
  <li class="clickable">
    <a routerLink="/dispatch" (click)="linkClicked()" id="dashboardSideMenuLink">Dispatch</a>
  </li>
  <div id="border-line"></div>
  <li>Openings</li>
  <ul class="sub-side-menu">
    <li *ngFor="let peak of peaks" class="clickable">
      <a routerLink="/daily/openings/{{ peak.value }}" (click)="linkClicked()">{{ peak.name }}</a>
    </li>
  </ul>
  <div id="border-line"></div>
  <li>Closings</li>
  <ul class="sub-side-menu">
    <li *ngFor="let peak of peaks" class="clickable">
      <a routerLink="/daily/closings/{{ peak.value }}" (click)="linkClicked()">{{ peak.name }}</a>
    </li>
  </ul>
  <div id="border-line"></div>
  <li>Worksheets</li>
  <ul class="sub-side-menu">
    <li *ngFor="let worksheet of worksheets" class="clickable">
      <a routerLink="/{{worksheet.value}}" (click)="linkClicked()">{{ worksheet.name }}</a>
    </li>
  </ul>
  <div id="border-line"></div>
  <li>Tools</li>
  <ul class="sub-side-menu">
    <li class="clickable">
      <a routerLink="/useful-links" (click)="linkClicked()" id="dashboardSideMenuLink">Useful Links</a>
    </li>
    <li class="clickable">
      <a routerLink="/directory" (click)="linkClicked()" id="dashboardSideMenuLink">Directory</a>
    </li>
    <li class="clickable">
      <a routerLink="/expired-meds" (click)="linkClicked()" id="dashboardSideMenuLink">Medical Inventory</a>
    </li>
    <li *ngIf="isSup()" class="clickable">
      <a routerLink="/roster" (click)="linkClicked()" id="dashboardSideMenuLink">Roster</a>
    </li>
    <li *ngIf="isSup()" class="clickable">
      <a routerLink="/reporting" (click)="linkClicked()" id="dashboardSideMenuLink">Reporting</a>
    </li>
  </ul>
  <div id="border-line"></div>
  <div class="user-info">
    <h6>Logged in as:</h6>  
    <h5>{{ currentUserName }}</h5>
    <button mat-raised-button (click)="logoutClicked()">Logout</button>
  </div>
</ul>
