import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { User } from '../../objects/user';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css']
})
export class ReportingComponent implements OnInit, OnDestroy {

  patrollerQueryId: string;
  patrollerQueryType: string;
  peak: string;
  patrollerQueryResult: string;

  patrollers: User[];
  startDate: moment.Moment = moment();
  endDate: moment.Moment = moment();

  subscription = new Subscription();

  constructor(private userService: UserService, private queryService: QueryService) { }

  ngOnInit() {
    this.subscription.add(this.userService.getCurrentAndFormerPatrollers().subscribe(patrollers => this.patrollers = patrollers));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onPatrollerQuery() {
    this.queryService.getCalloutsForPatroller(this.patrollerQueryType, this.patrollerQueryId, this.peak).pipe(take(1)).subscribe(callouts => {
      this.patrollerQueryResult = callouts.length.toString();
    });
  }

  onStartDateChanged() {}

  onEndDateChanged() {}
}
