import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { WeatherHistorical } from '../../../objects/weather-historical';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-over-month',
  templateUrl: './month-over-month.component.html',
  styleUrls: ['./month-over-month.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class MonthOverMonthComponent implements OnInit {

  @Input() nums: WeatherHistorical[];

  date = new FormControl(moment());

  monthlyAverage = 0;
  stdAverage = 0;
  baseAverage = 0;
  seasonAverage = 0;
  thisMonth = 0;
  thisStd = 0;
  thisBase = 0;
  thisMonthName = "";
  thisYear = 0;
  thisSeasonTotal = 0;

  basePercentage = 0;
  stdPercentage = 0;
  monthlyPercentage = 0;

  errorMessage = "";

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateData();
      this.getSeasonAverage();
    }, 1000);
  }

  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();

    this.updateData();
  }

  updateData() {
    let mom = this.date.value;
    this.thisYear = mom.year();
    switch (mom.month() + 1) {
      case 1:
        this.createJanAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      case 2:
        this.createFebAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      case 3:
        this.createMarAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      case 4:
        this.createAprAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      case 11:
        this.createNovAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      case 12:
        this.createDecAnalysis(this.thisYear);
        this.errorMessage = "";
        break;
      default:
        this.errorMessage = "We don't record data during that month.";
    }
    this.monthlyPercentage = Math.round(this.thisMonth/this.monthlyAverage * 100);
    this.stdPercentage = Math.round(this.thisStd/this.stdAverage * 100);
    this.basePercentage = Math.round(this.thisBase/this.baseAverage * 100);
  }

  getSeasonAverage() {
    let count = 0;
    let runningTotal = 0;

    this.nums.forEach(season => {
      runningTotal += season.getSeasonTotal();
      count++;
    });

    this.seasonAverage = Math.round(runningTotal / count);
  }

  createJanAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForSecondYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.janTotal;
      if (season.janBase) {
        runningBaseTotal += season.janBase;
        baseCount++;
      }
      runningStdTotal += season.janToDate();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.janTotal;
        this.thisStd = season.janToDate();
        this.thisBase = season.janBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "January";
  }

  createFebAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForSecondYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.febTotal;
      if (season.febBase) {
        runningBaseTotal += season.febBase;
        baseCount++;
      }
      runningStdTotal += season.febToDate();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.febTotal;
        this.thisStd = season.febToDate();
        this.thisBase = season.febBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "February";
  }

  createMarAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForSecondYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.marTotal;
      if (season.marBase) {
        runningBaseTotal += season.marBase;
        baseCount++;
      }
      runningStdTotal += season.marToDate();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.marTotal;
        this.thisStd = season.marToDate();
        this.thisBase = season.marBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "March";
  }

  createAprAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForSecondYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.aprTotal;
      if (season.aprBase) {
        runningBaseTotal += season.aprBase;
        baseCount++;
      }
      runningStdTotal += season.getSeasonTotal();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.aprTotal;
        this.thisStd = season.getSeasonTotal();
        this.thisBase = season.aprBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "April";
  }

  createNovAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForFirstYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.novTotal;
      if (season.novBase) {
        runningBaseTotal += season.novBase;
        baseCount++;
      }
      runningStdTotal += season.novToDate();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.novTotal;
        this.thisStd = season.novToDate();
        this.thisBase = season.novBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "November";
  }

  createDecAnalysis(year: number) {
    let runningMonthlyTotal = 0;
    let runningStdTotal = 0;
    let runningBaseTotal = 0;
    let count = 0;
    let baseCount = 0;

    let seasonId: string = this.getSeasonForFirstYearOfSeason(year);

    this.nums.forEach(season => {
      runningMonthlyTotal += season.decTotal;
      if (season.decBase) {
        runningBaseTotal += season.decBase;
        baseCount++;
      }
      runningStdTotal += season.decToDate();
      count++;

      // Check for the target year's id match.
      if (season.id == seasonId) {
        this.thisMonth = season.decTotal;
        this.thisStd = season.decToDate();
        this.thisBase = season.decBase;
        this.thisSeasonTotal = season.getSeasonTotal();
      }
    });

    this.monthlyAverage = Math.round(runningMonthlyTotal / count);
    this.stdAverage = Math.round(runningStdTotal / count);
    this.baseAverage = Math.round(runningBaseTotal / baseCount);
    this.thisMonthName = "December";
  }

  getSeasonForFirstYearOfSeason(year: number): string {
    let secondYear = year + 1;
    return year.toString() + secondYear.toString();
  }

  getSeasonForSecondYearOfSeason(year: number): string {
    let firstYear = year - 1;
    return firstYear.toString() + year.toString();
  }
}
