import { Component, OnInit } from '@angular/core';
import { Moment } from 'moment';
import { take } from 'rxjs/operators';
import { DailyWeatherObservation } from '../../objects/daily-weather-observation';
import { WeatherService } from '../../services/weather.service';
import * as moment from 'moment';

@Component({
  selector: 'app-weather-trends',
  templateUrl: './weather-trends.component.html',
  styleUrls: ['./weather-trends.component.scss']
})
export class WeatherTrendsComponent implements OnInit {

  startDate: Moment;
  endDate: Moment = moment().clone().endOf('day');

  weatherObsRange: DailyWeatherObservation[] = [];

  querySelection = "summary";

  constructor(private weatherService: WeatherService) { }

  ngOnInit() {
    // Default to Month to Date.
    this.rangeButtonClick('mtd');
    this.onUpdateRangeClick();
  }

  onUpdateRangeClick() {
    // Set end date to the end of the day selected.
    this.endDate = this.endDate.clone().endOf('day');
    
    this.weatherService.getWeatherObsForDateRange(this.startDate, this.endDate).pipe(take(1)).subscribe(obs => {
      this.weatherObsRange = obs;
    });
  }

  rangeButtonClick(val: string) {
    switch (val) {
      case 'mtd':
        this.startDate = moment().clone().startOf('month');
        break;
      case 'std':
        let currentYear = moment().clone().year();
        let currentMonth = moment().clone().month();
        let targetYear = currentYear;

        // If it's October or later, use same year's November.
        // If it's September or earlier, use last year's November.
        if (currentMonth < 9) {
          targetYear -= 1;  
        }

        this.startDate = moment([targetYear, 10, 1]);
        break;
      case '30':
        this.startDate = this.endDate.clone().subtract(30, 'days');
        break;
    }

    this.onUpdateRangeClick();
  }  
}
