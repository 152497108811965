<div class="uploader" *ngIf="isSpecialist()">
    <h3 class="upload-title">Upload new documents</h3>
    <input class="choose-file-input" type="file" (change)="updateFile($event)" accept=".pdf"/>
    <button class="upload-button" mat-raised-button color="primary" (click)="upload()">Upload</button>
    <div *ngIf="currentUpload">
        <br>
        <mat-progress-bar class="progress-bar" max="100" [value]="(uploadProgress | async)"></mat-progress-bar>
        <span class="completion-percentage">{{uploadProgress | async}} % complete</span>
    </div>

    <br>
    <br> 

    <input class="choose-file-input" type="file" (change)="updateFile2($event)" accept=".pdf"/>
    <button class="upload-button" mat-raised-button color="primary" (click)="upload2()">Upload</button>
    <div *ngIf="currentUpload2">
        <br>
        <mat-progress-bar class="progress-bar" max="100" [value]="(uploadProgress2 | async)"></mat-progress-bar>
        <span class="completion-percentage">{{uploadProgress2 | async}} % complete</span>
    </div>
</div>

<pdf-viewer [src]="pdfSrc"></pdf-viewer>
<pdf-viewer [src]="pdf2Src"></pdf-viewer>
