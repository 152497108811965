<h2>6am report</h2>

<div id="report-container">
    <p id="date-field">
        <mat-form-field>
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="currentDate" (dateChange)="onDateChanged()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </p>

    <mat-form-field id="patroller-dropdown">
        <mat-select placeholder="Reporter" [(ngModel)]="report.reporterId">
          <mat-option *ngFor="let patroller of patrollers" [value]="patroller.uid">
            {{ patroller.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <h4>Weather</h4>
    <div class="report-row-title">Prospector observations</div>
    <div class="report-row">
        <mat-form-field class="short-input">
            <mat-label>High</mat-label>
            <input type="number" matInput placeholder="12.3" [(ngModel)]="report.high24">
            <span matSuffix>°F</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>Low</mat-label>
            <input type="number" matInput placeholder="1.2" [(ngModel)]="report.low24">
            <span matSuffix>°F</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>@5am</mat-label>
            <input type="number" matInput placeholder="6.3" [(ngModel)]="report.temp5am">
            <span matSuffix>°F</span>
            <mat-hint></mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>Snow</mat-label>
            <input type="number" matInput placeholder="-9.5" [(ngModel)]="report.snowTemp20cm">
            <span matSuffix>°C</span>
            <mat-hint>temp 20cm</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>RH</mat-label>
            <input  type="number" matInput placeholder="56" [(ngModel)]="report.relativeHumidity">
            <span matSuffix>%</span>
            <mat-hint></mat-hint>
        </mat-form-field>
        <mat-form-field class="long-input">
            <mat-label>BP</mat-label>
            <input  type="number" matInput placeholder="30.32" [(ngModel)]="report.barometricPressure">
            <span matSuffix>mm Hg</span>
            <mat-hint></mat-hint>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <mat-label>BP Trend</mat-label>
            <mat-select [(ngModel)]="report.bpTrend">
              <mat-option value="falling">falling</mat-option>
              <mat-option value="steady">steady</mat-option>
              <mat-option value="rising">rising</mat-option>
            </mat-select>
          </mat-form-field>
    </div>
    <br>
    <div class="report-row-title">Wind Study observations</div>
    <div class="report-row">
        <mat-form-field class="short-input">
            <mat-label>Min</mat-label>
            <input type="number" matInput placeholder="15" [(ngModel)]="report.currentWindMin">
            <span matSuffix>mph</span>
            <mat-hint>curr. range</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>Max</mat-label>
            <input type="number" matInput placeholder="25" [(ngModel)]="report.currentWindMax">
            <span matSuffix>mph</span>
            <mat-hint>curr. range</mat-hint>
        </mat-form-field>
        <mat-form-field class="medium-input">
            <mat-label>Wind dir</mat-label>
            <mat-select [(ngModel)]="report.currentWindDir">
              <mat-option value="N">N</mat-option>
              <mat-option value="NNE">NNE</mat-option>
              <mat-option value="NE">NE</mat-option>
              <mat-option value="ENE">ENE</mat-option>
              <mat-option value="E">E</mat-option>
              <mat-option value="ESE">ESE</mat-option>
              <mat-option value="SE">SE</mat-option>
              <mat-option value="SSE">SSE</mat-option>
              <mat-option value="S">S</mat-option>
              <mat-option value="SSW">SSW</mat-option>
              <mat-option value="SW">SW</mat-option>
              <mat-option value="WSW">WSW</mat-option>
              <mat-option value="W">W</mat-option>
              <mat-option value="WNW">WNW</mat-option>
              <mat-option value="NW">NW</mat-option>
              <mat-option value="NNW">NNW</mat-option>
            </mat-select>
            <mat-hint>curr. range</mat-hint>
          </mat-form-field>

          <mat-form-field class="medium-input">
            <mat-label>Max gust</mat-label>
            <input type="number" matInput placeholder="45" [(ngModel)]="report.maxGust24">
            <span matSuffix>mph</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
    </div>

    <br>
    <div class="report-row-title">Manual observations</div>
    <div class="report-row">
        <mat-form-field class="long-input">
            <mat-label>Sky conditions</mat-label>
            <mat-select [(ngModel)]="report.skyConditions">
              <mat-option value="Clear">Clear</mat-option>
              <mat-option value="Few">Few</mat-option>
              <mat-option value="Scattered">Scattered</mat-option>
              <mat-option value="Broken">Broken</mat-option>
              <mat-option value="Overcast">Overcast</mat-option>
              <mat-option value="Obscured">Obscured</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="long-input">
            <mat-label>Precip</mat-label>
            <mat-select [(ngModel)]="report.currentPrecip">
              <mat-option value="None">None</mat-option>
              <mat-option value="Light snow">Light snow (S1) [1cm/hr]</mat-option>
              <mat-option value="Moderate snow">Moderate snow (S2) [2cm/hr]</mat-option>
              <mat-option value="Heavy snow">Heavy snow (S5) [5cm/hr]</mat-option>
              <mat-option value="Rain">Rain</mat-option>
              <mat-option value="Graupel">Graupel</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <br>
    <div class="report-row-title">Snow observations</div>
    <div class="report-row">
        <mat-form-field class="short-input">
            <mat-label>Peru HN</mat-label>
            <input type="number" matInput placeholder="2" [(ngModel)]="report.peruHn">
            <span matSuffix>"</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>Peru Base</mat-label>
            <input type="number" matInput placeholder="42" [(ngModel)]="report.peruHs">
            <span matSuffix>"</span>
            <mat-hint>season</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>NP HN</mat-label>
            <input type="number" matInput placeholder="2" [(ngModel)]="report.npHn">
            <span matSuffix>"</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>NP Base</mat-label>
            <input type="number" matInput placeholder="42" [(ngModel)]="report.npHs">
            <span matSuffix>"</span>
            <mat-hint>season</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>OB HN</mat-label>
            <input type="number" matInput placeholder="2" [(ngModel)]="report.obHn">
            <span matSuffix>"</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
        <mat-form-field class="short-input">
            <mat-label>OB Base</mat-label>
            <input type="number" matInput placeholder="42" [(ngModel)]="report.obHs">
            <span matSuffix>"</span>
            <mat-hint>season</mat-hint>
        </mat-form-field>

        <mat-form-field class="short-input">
            <mat-label>SWE</mat-label>
            <input type="number" matInput placeholder="0.5" [(ngModel)]="report.swe24">
            <span matSuffix>"</span>
            <mat-hint>last 24hrs</mat-hint>
        </mat-form-field>
    </div>
    <br>
    <h4>Terrain</h4>
    <div class="report-row">
        <mat-form-field class="long-input">
            <mat-label>Open acres</mat-label>
            <input type="number" matInput placeholder="1234" [(ngModel)]="report.openAcres">
            <span matSuffix>acres</span>
            <mat-hint></mat-hint>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Notable terrain openings</mat-label>
            <mat-select [(ngModel)]="report.notableTerrainOpenings" multiple>
              <mat-option value="Outback">Outback</mat-option>
              <mat-option value="Independence Bowl">Independence Bowl</mat-option>
              <mat-option value="Erickson Bowl">Erickson Bowl</mat-option>
              <mat-option value="South Bowl">South Bowl</mat-option>
              <mat-option value="North Bowl">North Bowl</mat-option>
              <mat-option value="Windows">Windows</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    
    <br>

    <div id="buttons-container">
        <button mat-raised-button color="primary" (click)="onSubmitClick()">Submit</button>
        <button mat-raised-button (click)="onClearClick()">Clear</button>
        <br>
        <button mat-flat-button (click)="onEditHistoricalClick()">Edit historical data</button>
    </div>
</div>