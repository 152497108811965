<div id="month-container">
    <mat-form-field>
        <mat-label>Choose a month</mat-label>
        <input matInput [matDatepicker]="monthpicker" [formControl]="date">
        <mat-datepicker-toggle matSuffix [for]="monthpicker"></mat-datepicker-toggle>
        <mat-datepicker #monthpicker startView="multi-year" (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, monthpicker)"></mat-datepicker>
    </mat-form-field>

    <div id="averages">
        <p>{{errorMessage}}</p>
        <table>
            <tr>
                <th></th>
                <th>{{thisMonthName}} {{thisYear}}</th>
                <th>{{thisMonthName}} average</th>
                <th>% of average</th>
            </tr>
            <tr>
                <th class="righty">Monthly total</th>
                <td>{{thisMonth}}"</td>
                <td>{{monthlyAverage}}"</td>
                <td>{{monthlyPercentage}}%</td>
            </tr>
            <tr>
                <th class="righty">Season-to-date</th>
                <td>{{thisStd}}"</td>
                <td>{{stdAverage}}"</td>
                <td>{{stdPercentage}}%</td>
            </tr>
            <tr>
                <th class="righty bordered">Settled base</th>
                <td class="bordered">{{thisBase}}"</td>
                <td class="bordered">{{baseAverage}}"</td>
                <td class="bordered">{{basePercentage}}%</td>
            </tr>
            <tr>
                <th class="righty">Season totals</th>
                <td>{{thisSeasonTotal}}"</td>
                <td>{{seasonAverage}}"</td>
            </tr>
        </table>
        <br>
        <br>
        <p class="small-text">Averages for monthly total and season-to-date total date back to the 1979-1980 season with no gaps. Averages for settled base date back to the 2004-2005 season.</p>
    </div>
</div>
