export class DailyWeatherObservation {
    id: string;
    timestamp: number;

    reporterId: string;
    
    high24: number;
    low24: number;
    temp5am: number;
    snowTemp20cm: number;
    relativeHumidity: number;
    barometricPressure: number;
    bpTrend: string;
    
    currentWindMin: number;
    currentWindMax: number;
    currentWindDir: string;
    maxGust24: number;
    
    skyConditions: string;
    currentPrecip: string;

    npHn: number;
    npHs: number;
    peruHn: number;
    peruHs: number;
    obHn: number;
    obHs: number;
    swe24: number;

    openAcres: number;
    notableTerrainOpenings: string;
}
