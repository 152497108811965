import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ExpiredMed } from '../objects/expired-med';
import { TransactionService } from './transaction.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  
  date: moment.Moment = moment();

  constructor(private db: AngularFirestore, private transactionService: TransactionService) { }

  submitExpiredMedReport(report: ExpiredMed) {
    if (report.id == null) {
      report.id = this.db.createId();
    }
    let data = JSON.parse(JSON.stringify(report));
    this.transactionService.writeDataToDocForCollection(data, report.id, 'expired-meds');
  }

  getIncompleteExpiredMedReports(): Observable<any[]> {
    return this.db.collection('expired-meds', ref => ref.where('completed', '==', false)).valueChanges();
  }

  getCompleteExpiredMedReportsInLastSevenDays(): Observable<any[]> {
    
    // Use seven days ago until today.
    let startDate = this.date.toDate();
    startDate.setDate(startDate.getDate() - 7);

    let endDate = this.date.toDate();

    // Set the days to starting at midnight and ending at 11:59pm.
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    // Calling .getTime() converts to milliseconds which matches how timeCreated is stored.
    return this.db.collection('expired-meds', ref => ref.where('dateCompleted', '>', startDate.getTime())
                                                    .where('dateCompleted', '<', endDate.getTime())
                                                    .where('completed', '==', true)
                                                    .orderBy('dateCompleted', 'desc')).valueChanges();
  }

  deleteExpiredMedReport(report: ExpiredMed) {
    this.transactionService.deleteDocInCollection(report.id, 'expired-meds', report);
  }
}
