import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Wig } from '../objects/wig';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WigService {

  constructor(private db: AngularFirestore) { }

  getPointsForPeakToday(peak: string): Observable<any[]> {
    let start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    let end = new Date();
    end.setHours(23);
    end.setMinutes(59);
    return this.db.collection('wig', ref => ref.where('timeRecorded', '>', start.getTime())
                                                .where('timeRecorded', '<', end.getTime())
                                                .where("peak", "==", peak)).valueChanges();
  }

  addWig(wig: Wig) {
    wig.id = this.db.createId();
    wig.timeRecorded = new Date().getTime();
    let data = JSON.parse(JSON.stringify(wig));
    this.db.collection('wig').doc(wig.id).set(data);
  }
}
