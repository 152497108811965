<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<h2 mat-dialog-title>Add/edit training link</h2>
<mat-dialog-content>
  <div id="content-container">
    <mat-form-field>
        <input matInput placeholder="Title" name="title" [(ngModel)]="link.text">
    </mat-form-field>
    <mat-form-field>
        <input matInput placeholder="Link" name="link" [(ngModel)]="link.link">
    </mat-form-field>
    <br>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" (click)="onSubmitClicked()" [mat-dialog-close]="true">Submit</button>
  <button mat-raised-button color="accent" (click)="onDeleteClicked()" [mat-dialog-close]="true">Delete</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>