import { Component, Input, OnInit } from '@angular/core';
import { WeatherHistorical } from '../../../objects/weather-historical';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() nums: WeatherHistorical[];
  
  displayedColumns = ['season', 'pre', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'total'];
  
  showBases = false;

  constructor() {}

  ngOnInit(): void {
  }
}
