import { Moment } from 'moment';

export class MorningNote {
    text: string;
    author: string;
    expires: string;
    posted: string;
    id: string;
    expiryMoment: Moment;
    archived: boolean = false;
}
