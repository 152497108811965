import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WeatherHomeComponent } from './views/weather-home/weather-home.component';
import { WeatherQueryComponent } from './views/weather-query/weather-query.component';
import { WeatherReportComponent } from './views/weather-report/weather-report.component';
import { WeatherTrendsComponent } from './views/weather-trends/weather-trends.component';

// All route paths are prefixed with 'weather/home' because this is a feature module.
// The mechanism is the App Routing Module, which lazy loads this module when the user navigates to weather/home
// i.e. '' is weather/home
const weatherRoutes: Routes = [
  { path: 'weather', children: [
    { path: 'home', component: WeatherHomeComponent, data: {title: 'Weather Home'} },
    { path: 'report', component: WeatherReportComponent, data: {title: 'Report Weather'} },
    { path: 'trends', component: WeatherTrendsComponent, data: {title: 'Weather Trends'} },
    { path: 'query', component: WeatherQueryComponent, data: {title: 'Weather Query'} }
  ]},
  { path: '', redirectTo: '/weather/home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    // The .forChild(routes) call is imperative as .forRoot(routes) is called in the App Routing Module
    //  and it cannot be called twice.
    // In other words, these routes are auxiliary.
    RouterModule.forChild(weatherRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class WeatherRoutingModule { }
