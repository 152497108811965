import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective, Label } from 'ng2-charts';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DailyWeatherObservation } from '../../../objects/daily-weather-observation';

@Component({
  selector: 'app-peaks-comparison',
  templateUrl: './peaks-comparison.component.html',
  styleUrls: ['./peaks-comparison.component.scss']
})
export class PeaksComparisonComponent implements OnChanges {

  lineChartData: ChartDataSets[];
  lineChartLabels: Label[];
  lineChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          id: 'base',
          position: 'left',
          scaleLabel: { display: true, labelString: 'Base depth (inches)' },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() nums: DailyWeatherObservation[];

  ngOnChanges() {
    this.updateDataForChart();
  }

  updateDataForChart() {
    let peruSeries = [];
    let npSeries = [];
    let obSeries = [];
    let labels = [];

    this.nums.forEach(ob => {
      peruSeries.push(ob.peruHs);
      npSeries.push(ob.npHs);
      obSeries.push(ob.obHs);
      labels.push(new Date(ob.timestamp).toLocaleDateString());
    });

    this.lineChartLabels = labels;

    this.lineChartData = [
      { data: peruSeries, label: 'Peru', steppedLine: 'before' },
      { data: npSeries, label: 'North Peak', steppedLine: 'middle' },
      { data: obSeries, label: 'Outback', steppedLine: 'after' },
    ];
    
  }
}
