import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherHomeComponent } from './views/weather-home/weather-home.component';
import { WeatherRoutingModule } from './weather-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { WeatherReportComponent } from './views/weather-report/weather-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WeatherTrendsComponent } from './views/weather-trends/weather-trends.component';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EditHistoricalComponent } from './views/edit-historical/edit-historical.component';
import { MatDialogModule } from '@angular/material/dialog';
import { WeatherQueryComponent } from './views/weather-query/weather-query.component';
import { TableComponent } from './views/weather-query/table/table.component';
import { MonthOverMonthComponent } from './views/weather-query/month-over-month/month-over-month.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SummaryComponent } from './views/weather-trends/summary/summary.component';
import { PeaksComparisonComponent } from './views/weather-trends/peaks-comparison/peaks-comparison.component';
import { BpStormsComponent } from './views/weather-trends/bp-storms/bp-storms.component';

@NgModule({
  declarations: [WeatherHomeComponent, WeatherReportComponent, WeatherReportComponent, WeatherTrendsComponent, EditHistoricalComponent, WeatherQueryComponent, TableComponent, MonthOverMonthComponent, SummaryComponent, PeaksComparisonComponent, BpStormsComponent],
  imports: [
    CommonModule,
    WeatherRoutingModule,
    MatListModule, MatIconModule, MatInputModule, MatDatepickerModule, FormsModule, MatSelectModule, MatButtonModule,
    MatSnackBarModule, MatDialogModule, MatTableModule, MatSlideToggleModule, MatFormFieldModule, ReactiveFormsModule,
    ChartsModule
  ], 
  providers: [ThemeService]
})
export class WeatherModule { }
