<h2>Reporting Tools</h2>

<div id="query-container" class="container">
    <!-- <mat-form-field class="date-picker">
        <input matInput [matDatepicker]="startDatePicker" 
        placeholder="Start date" [(ngModel)]="startDate"
        (dateChange)="onStartDateChanged()">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field class="date-picker">
        <input matInput [matDatepicker]="endDatePicker" 
        placeholder="End date" [(ngModel)]="endDate"
        (dateChange)="onEndDateChanged()">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field> -->

    <br>

    <div class="query-box">
        <h4>Patroller queries</h4>
        <mat-form-field class="needs-space">
            <mat-select placeholder="Patroller"
            [(ngModel)]="patrollerQueryId" name="patrollerInQuestion">
                <mat-option *ngFor="let patroller of patrollers" [value]="patroller.uid">
                {{ patroller.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="needs-space">
            <mat-select placeholder="Type of call-out"
            [(ngModel)]="patrollerQueryType" name="type">
                <mat-option value="10-50">10-50</mat-option>
                <mat-option value="Taxi">Taxi</mat-option>
                <mat-option value="Refusal">Refusal</mat-option>
                <mat-option value="Non-event">Non-event</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="needs-space">
            <mat-select placeholder="Peak"
            [(ngModel)]="peak" name="peak">
                <mat-option value="any">Any</mat-option>
                <mat-option value="Frontside">Frontside</mat-option>
                <mat-option value="North">North Peak</mat-option>
                <mat-option value="Outback">Outback</mat-option>
                <mat-option value="Bergman">Bergman</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" class="needs-space" (click)="onPatrollerQuery()">Query</button>

        <span id="results-container" class="needs-space">{{patrollerQueryResult}}</span>
    </div>
</div>
