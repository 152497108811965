<h2>Weather</h2>

<mat-nav-list>
    <div mat-subheader>Actions</div>
    <!-- A reporting interface for Weather Storage Numbers -->
    <a mat-list-item routerLink="/weather/report">
        <mat-icon mat-list-icon>wb_sunny</mat-icon>
        <span>Report</span>
    </a>

    <a mat-list-item>
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <span>Sendout</span>
    </a>
    
    <mat-divider></mat-divider>
    
    <div mat-subheader>View</div>
    <!-- Ultimately this will have Season-to-date, Month-do-date, Last month, etc. options -->
    <a mat-list-item routerLink="/weather/trends">
        <mat-icon mat-list-icon>calendar_view_day</mat-icon>
        <span>Trends</span>
    </a>
    <!-- Queryable: snowfall totals, historical opening dates, average temps in time periods, etc. -->
    <a mat-list-item routerLink="/weather/query">
        <mat-icon mat-list-icon>query_stats</mat-icon>
        <span>Query</span>
    </a>
</mat-nav-list>
