<div *ngIf="!alreadyLoggedIn">
  <form [formGroup]="registerForm" class="login-form" *ngIf="newUser">
    
    <h2>Sign Up</h2>    
    <mat-form-field class="text-input">
      <input matInput type="text" name="firstName" placeholder="First name" 
      formControlName="firstName" required>
    </mat-form-field>
    <br>
    <mat-form-field class="text-input">
      <input matInput type="text" name="lastName" placeholder="Last name" 
      formControlName="lastName" required>
    </mat-form-field>
    <br>
    <mat-form-field class="text-input">
      <input matInput type="tel" name="phone" placeholder="Phone number" 
      formControlName="phone">
    </mat-form-field>
    <div class="form-errors" *ngIf="formErrors.phone">
      {{ formErrors.phone }}
    </div>
    <br>
    <mat-form-field class="text-input">
        <input matInput type="email" name="email" placeholder="Email" 
        formControlName="email" required>
    </mat-form-field>
    <div class="form-errors" *ngIf="formErrors.email">
      {{ formErrors.email }}
    </div>
    <br>
    <mat-form-field class="text-input">
        <input matInput type="password" name="password" placeholder="Password" 
        formControlName="password" required>
    </mat-form-field>
    <div class="form-errors" *ngIf="formErrors.password">
      {{ formErrors.password }}
    </div>
    <br>
    <mat-form-field class="text-input">
        <input matInput type="password" name="confirmedPassword" placeholder="Confirm password" 
        formControlName="confirmedPassword" required>
    </mat-form-field>
    <div class="form-errors" *ngIf="!passwordsMatch">
      Passwords do not match.
    </div>
    <br>
    <mat-form-field class="text-input">
        <input matInput name="patrolPassword" placeholder="Team password" 
        formControlName="patrolPassword" required>
    </mat-form-field>
    <br>
    <button mat-raised-button color="primary" (click)="signUpWithEmail()" [disabled]="!registerForm.valid">Register</button>
    <button mat-stroked-button class="second-button" color="secondary" (click)="toggleForm()">Already have an account?</button>
  </form>

  <form class="login-form" *ngIf="!newUser">
    <h2>Login</h2>
    <mat-form-field class="text-input">
        <input matInput type="email" name="email" placeholder="Email" 
        [(ngModel)]="existingUser.email">
    </mat-form-field>
    <br>
    <mat-form-field class="text-input">
        <input matInput type="password" name="password" placeholder="Password" 
        [(ngModel)]="existingUser.password">
    </mat-form-field>
    <br>
    <div *ngIf="hadLoginError">
      <p>{{ loginError }}</p>
      <br>
    </div>
    <button mat-raised-button color="primary" (click)="loginWithEmail()">Login</button>
    <button mat-stroked-button class="second-button" color="secondary" (click)="toggleForm()">Need to sign up?</button>
    <br>
    <br>
    <button mat-flat-button (click)="onForgotPasswordClick()">Forgot my dang password</button>
  </form>
</div>
<div class="login-form" *ngIf="alreadyLoggedIn">
  <p>You're already logged in!</p>
  <p>{{ currentUserEmail }}</p>
  <button mat-raised-button (click)="logoutClicked()">Logout</button>
</div>