<h2>Directory</h2>

<div id="container">
  <div id="header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search...">
    </mat-form-field>
    <button *ngIf="isSup()" mat-button color="primary" (click)="onEditButtonClicked(null)">Add Contact</button>
  </div>

  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z8">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td class="hoverable" mat-cell *matCellDef="let contact"> 
        {{contact.name}} 
      </td>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef>Edit</th>
      <td class="hoverable" mat-cell *matCellDef="let contact"> 
        <button *ngIf="isSup()" class="hover-buttons" mat-icon-button (click)="onEditButtonClicked(contact)">
          <mat-icon class="material-icons">edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Extension Column -->
    <ng-container matColumnDef="extension">
      <th mat-header-cell *matHeaderCellDef> Extension </th>
      <td mat-cell *matCellDef="let contact"> {{contact.extension}} </td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> Phone </th>
      <td mat-cell *matCellDef="let contact"> {{contact.phone}} </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let contact"> {{contact.email}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
  </table>
</div>