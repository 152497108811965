import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { DailyWeatherObservation } from '../objects/daily-weather-observation';
import { WeatherHistorical } from '../objects/weather-historical';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {

  constructor(private db: AngularFirestore) { }

  getWeatherObsForDate(date: Moment) {
    let id = this.convertDateToString(date.valueOf());
    return this.db.collection('weather-obs').doc(id).get();
  }

  getWeatherObsForDateRange(start: Moment, end: Moment): Observable<any[]> {
    return this.db.collection('weather-obs', ref => ref.where('timestamp', '>', start.valueOf()).where('timestamp', '<', end.valueOf())).valueChanges();
  }

  updateWeatherObs(report: DailyWeatherObservation) {
    report.id = this.convertDateToString(report.timestamp);
    let data = JSON.parse(JSON.stringify(report));
    this.db.collection('weather-obs').doc(report.id).set(data);
  }


  getHistoricalWeatherNums(): Observable<any[]> {
    return this.db.collection('weather-historical').valueChanges();
  }

  getWeatherConstants(): Observable<any[]> {
    return this.db.collection('weather-constants').valueChanges();
  }

  addHistoricalWeatherYear(year: WeatherHistorical) {
    let data = JSON.parse(JSON.stringify(year));
    this.db.collection('weather-historical').doc(year.id).set(data);
  }

  updateHistoricalWeatherYear(year: WeatherHistorical) {
    let data = JSON.parse(JSON.stringify(year));
    this.db.collection('weather-historical').doc(year.id).update(data);
  }

  updateWeatherConstants(consts: any) {
    let data = JSON.parse(JSON.stringify(consts));
    this.db.collection('weather-constants').doc('constants').update(data);
  }

  // Helpers.
  convertDateToString(ms: number): string {
    let date = new Date(ms);
    let str = date.getFullYear() + "" + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2);
    return str;
  }
}
